/*!
 *
 * @client			ASG
 * @description		main less
 * @author 		    Thomas Schüßler (www.thomasschuessler.de)
 * @copyright       (c) 2015 Thomas Schüßler (www.thomasschuessler.de)
 *
 */
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-SemiBold.eot');
  src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-SemiBold.woff2') format('woff2'), url('../fonts/OpenSans-SemiBold.woff') format('woff'), url('../fonts/OpenSans-SemiBold.ttf') format('truetype'), url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Regular.woff2') format('woff2'), url('../fonts/OpenSans-Regular.woff') format('woff'), url('../fonts/OpenSans-Regular.ttf') format('truetype'), url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Bold.woff2') format('woff2'), url('../fonts/OpenSans-Bold.woff') format('woff'), url('../fonts/OpenSans-Bold.ttf') format('truetype'), url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/* LESS Document */
body {
  /*  padding-top: 70px; Required padding for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #8b8880;
  background: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  /* Fix for webkit rendering */
  -moz-osx-font-smoothing: grayscale;
}
body.auswahlseite {
  background-image: url('../img/auswahlseite-htg.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
a,
a:hover {
  color: #8b8880;
}
a:focus,
input:focus {
  outline: 0;
}
h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 26px;
  color: #fd9128;
}
.smartquality h1 {
  color: #fd9128;
}
.page-header {
  border-bottom: none;
  margin: 0px 0 20px;
  padding-bottom: 9px;
}
h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 26px;
}
.orange {
  color: #fd9128;
}
.small-text {
  font-size: 13px;
}
header {
  height: 141px;
}
footer {
  background: #8b8880;
  margin: 50px 0 0;
  min-height: 240px;
  padding-bottom: 50px;
  color: #cecdcd;
}
.logo {
  position: absolute;
  top: 41px;
  left: 10px;
}
@media (max-width: 767px) {
  .logo {
    /* xs */
    top: 30px;
  }
  .logo img {
    width: 215px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .logo {
    /* sm */
    top: 50px;
  }
  .logo img {
    width: 215px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .logo {
    /* md */
    top: 58px;
  }
  .logo img {
    width: 290px;
  }
}
.no-padding {
  padding: 0 !important;
}
.teaser-item {
  margin-bottom: 25px;
}
.header-col {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .img-marginleft {
    margin-left: 70px;
  }
}
a.hon-link {
  display: inline;
}
/* .home a.hon-link {display: inline} */
a.hon-link:after {
  content: "" !important;
}
.readmore .btn {
  padding: 0;
  margin-bottom: 20px;
}
.readmore a:after {
  content: " \00BB";
  color: #fd9128;
}
.alert-message {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-error,
.alert-error .alert-message {
  background-color: #f4a8a7;
  border-color: #a94442;
  color: #a94442;
}
.alert-warning .alert-message {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* import Menu */
/* NAVIGATION
 ------------------------------- */
.navcontainer {
  position: relative;
  z-index: 1000;
}
@media (max-width: 767px) {
  .navbar-collapse {
    background-color: #f7f7f7;
    border-bottom: 1px solid #000;
  }
}
.navbar {
  min-height: 170px;
  height: 170px;
  /* overflow: hidden; */
  border: none;
  background-color: transparent;
  /* @brand-primary */
}
@media (max-width: 767px) {
  .navbar {
    /* xs */
    min-height: 115px;
    height: 115px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar {
    /* sm */
    min-height: 130px;
    height: 130px;
  }
}
@media (min-width: 768px) {
  .navbar-nav {
    position: absolute;
    right: 0px;
    top: 82px;
  }
}
.navbar-nav > li {
  margin-right: 27px;
}
@media (max-width: 991px) {
  .navbar-nav > li {
    margin-right: 0px;
  }
}
.navbar-nav > li > a {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.05em;
  padding: 50px 0 5px;
  line-height: 16px;
  min-height: 70px;
  height: 70px;
}
@media (max-width: 767px) {
  .navbar-nav > li > a {
    /* xs */
    font-size: 15px;
    padding: 14px 15px 10px;
    min-height: 45px;
    height: 45px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-nav > li > a {
    /* sm */
    font-size: 15px;
    padding: 22px 0 5px;
    margin-right: 20px;
    min-height: 40px;
    height: 40px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-nav > li > a {
    /* md */
    font-size: 18px;
    padding: 52px 0 5px;
  }
}
/* NAVBAR TOP COLORS */
.navbar-default .navbar-nav > li > a {
  color: #8b8880;
  background-color: transparent;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #8b8880;
  background-color: transparent;
}
.navbar-default .navbar-nav > .current > a,
.navbar-default .navbar-nav > .current > a:hover,
.navbar-default .navbar-nav > .current > a:focus,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #8b8880;
  background-color: transparent;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  color: #8b8880;
  background-color: transparent;
}
@media (min-width: 992px) {
  /*
  * Buttons Schmerztherapie
  */
  /* Button Team */
  .main-nav .icon-team {
    background: url('../img/icon-team.jpg') top center no-repeat;
  }
  .main-nav .icon-team:hover,
  .main-nav .current > .icon-team,
  .main-nav .active > .icon-team {
    background: url('../img/icon-team-active.jpg') top center no-repeat;
  }
  /* Button Schwerpunkte */
  .main-nav .icon-schwerpunkte {
    background: url('../img/icon-schwerpunkte.jpg') top center no-repeat;
  }
  .main-nav .icon-schwerpunkte:hover,
  .main-nav .current > .icon-schwerpunkte,
  .main-nav .active > .icon-schwerpunkte {
    background: url('../img/icon-schwerpunkte-active.jpg') top center no-repeat;
  }
  /* Button Service */
  .main-nav .icon-service {
    background: url('../img/icon-service.jpg') top center no-repeat;
  }
  .main-nav .icon-service:hover,
  .main-nav .current > .icon-service,
  .main-nav .active > .icon-service {
    background: url('../img/icon-service-active.jpg') top center no-repeat;
  }
  /* Button Meinung */
  .main-nav .icon-meinung {
    background: url('../img/icon-meinung.jpg') top center no-repeat;
  }
  .main-nav .icon-meinung:hover,
  .main-nav .current > .icon-meinung,
  .main-nav .active > .icon-meinung {
    background: url('../img/icon-meinung-active.jpg') top center no-repeat;
  }
  /* Button Kontakt */
  .main-nav .icon-kontakt {
    background: url('../img/icon-kontakt.jpg') top center no-repeat;
  }
  .main-nav .icon-kontakt:hover,
  .main-nav .current > .icon-kontakt,
  .main-nav .active > .icon-kontakt {
    background: url('../img/icon-kontakt-active.jpg') top center no-repeat;
  }
  /*
  * Buttons Anästhesie
  */
  /* Button Team */
  .main-nav .item-145 > a {
    background: url('../img/icon-team.jpg') top center no-repeat;
  }
  .main-nav .item-145 > a:hover,
  .main-nav .item-145.current > a,
  .main-nav .item-145.active > a {
    background: url('../img/icon-team-active.jpg') top center no-repeat;
  }
  .main-nav .item-145 .dropdown-menu li {
    /* Untermenu hier unsichtbar */
  }
  /* Button Narkosen */
  .main-nav .item-146 > a {
    background: url('../img/icon-narkosen.jpg') top center no-repeat;
  }
  .main-nav .item-146 > a:hover,
  .main-nav .item-146.current > a,
  .main-nav .item-146.active > a {
    background: url('../img/icon-narkosen-active.jpg') top center no-repeat;
  }
  /* Button Standorte */
  .main-nav .item-147 > a {
    background: url('../img/icon-standorte.jpg') top center no-repeat;
  }
  .main-nav .item-147 > a:hover,
  .main-nav .item-147.current > a,
  .main-nav .item-147.active > a {
    background: url('../img/icon-standorte-active.jpg') top center no-repeat;
  }
  /* Button Info */
  .main-nav .item-148 > a {
    background: url('../img/icon-info.jpg') top center no-repeat;
  }
  .main-nav .item-148 > a:hover,
  .main-nav .item-148.current > a,
  .main-nav .item-148.active > a {
    background: url('../img/icon-info-active.jpg') top center no-repeat;
  }
  /* Button Kontakt */
  .main-nav .item-149 > a {
    background: url('../img/icon-kontakt.jpg') top center no-repeat;
  }
  .main-nav .item-149 > a:hover,
  .main-nav .item-149.current > a,
  .main-nav .item-149.active > a {
    background: url('../img/icon-kontakt-active.jpg') top center no-repeat;
  }
}
/* DROPDOWN Mobile (xs) */
@media (max-width: 767px) {
  /* xs */
  .dropdown-menu > li > a {
    color: #FFF;
    padding: 3px 17px 3px !important;
    font-weight: 600;
    font-size: 14px;
    margin: 4px 0;
  }
}
/* DROPDOWN other */
@media (min-width: 768px) {
  .dropdown-menu {
    background: url("../img/dropdown-arrow.gif") top center no-repeat #f7f7f7;
    border: none;
    min-width: 100%;
    padding-top: 21px;
    box-shadow: none;
    padding-bottom: 0px;
  }
  .navbar-nav > li > .dropdown-menu {
    margin-top: -1px;
  }
  .dropdown-menu > li > a {
    color: #FFF;
    padding: 6px 18px 7px;
    background: url("../img/dropdown-item-bg.png") bottom repeat-x;
    font-weight: 600;
    font-size: 15px;
  }
  .dropdown-menu > li > a:hover,
  .dropdown-menu > li.current > a,
  .dropdown-menu > li.active > a {
    background-color: #fd9128;
    background-image: none;
    color: #fff;
  }
}
/* FOOTER */
.footerlist {
  margin-top: 42px;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .footerlist {
    /* sm */
    font-size: 13px;
  }
}
.footerlist a {
  color: #cecdcd;
  text-decoration: none;
}
.footerlist > li {
  width: 20%;
  float: left;
}
@media (max-width: 767px) {
  .footerlist > li {
    width: 100%;
    padding-bottom: 20px;
  }
}
.footerlist > li > a {
  font-weight: bold;
}
.footerlist > li > ul {
  padding-left: 0px;
  margin-left: 0px;
  list-style-type: none;
}
footer .footer-nav {
  min-height: 190px;
}
@media (min-width: 1200px) {
  footer .short-nav {
    text-align: right;
    margin-top: 42px;
  }
}
footer .short-nav a {
  color: #cecdcd;
  font-weight: bold;
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  footer .short-nav a {
    /* sm */
    font-size: 13px;
  }
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Slider */
.default-modal .ju-slide-btns .prev_button {
  margin-left: 10px;
}
.default-modal .ju-slide-btns .next_button {
  margin-right: 10px;
}
.ju-slideshow .ju-slide-navigation-dots {
  bottom: -32px;
}
.slideshowMeinung .ju-slide-btns .prev_button,
.slideshowMeinung .ju-slide-btns .next_button {
  margin-top: -215px;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* content homepage */
.item-page,
.item-pagehome {
  background-color: #ececeb;
  padding: 17px 30px;
  min-height: 320px;
  margin-bottom: 30px;
}
.item-page a:after,
.item-pagehome a:after {
  content: " \00BB";
  color: #fd9128;
}
.item-page ul,
.item-pagehome ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.item-page li,
.item-pagehome li {
  padding-left: 1em;
  text-indent: -0.9em;
}
.item-page li:before,
.item-pagehome li:before {
  content: "• ";
  color: #fd9128;
  /* or whatever color you prefer */
}
.smartquality .item-page a:after,
.smartquality .item-pagehome a:after {
  content: " \00BB";
  color: #fd9128;
}
.smartquality .item-page li:before,
.smartquality .item-pagehome li:before {
  content: "• ";
  color: #fd9128;
  /* or whatever color you prefer */
}
.smartquality .item-pagehome {
  min-height: 359px;
}
.btn-primary {
  background-color: #fd9128;
  border-color: #fd9128;
  color: #fff;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Content Elemente */
.praxis-bilder-block {
  /* div */
  display: block;
  margin-left: -10px;
  margin-right: -15px;
}
.praxis-room {
  /* image */
  margin: 10px;
  width: 158px;
}
@media (max-width: 767px) {
  .praxis-room {
    /* xs */
    width: 120px;
  }
}
@media (max-width: 350px) {
  .praxis-room {
    /* xs mini */
    width: 100px;
  }
}
.team-bilder-block {
  /* div */
  display: block;
  margin-left: -10px;
  margin-right: -15px;
}
.team-bilder-block a {
  display: inline-block;
  vertical-align: top;
  margin: 10px;
  width: 154px;
  text-decoration: none;
}
@media (max-width: 767px) {
  .team-bilder-block a {
    /* xs */
    width: 120px;
  }
}
@media (max-width: 350px) {
  .team-bilder-block a {
    /* xs mini */
    width: 100px;
  }
}
.team-bilder-block a:after {
  content: "";
  color: transparent;
}
.team-image {
  /* image */
  width: 100%;
}
.team-bilder-block-breit {
  margin-top: 30px;
}
.team-image-container {
  display: inline-block;
  vertical-align: top;
  width: 269px;
  margin-right: 43px;
}
@media (max-width: 767px) {
  .team-image-container {
    /* xs */
    width: 260px;
    margin-right: 0px;
  }
}
@media (max-width: 350px) {
  .team-image-container {
    /* xs mini */
    width: 240px;
    margin-right: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .team-image-container {
    /* md */
    width: 256px;
    margin-right: 19px;
  }
}
.team-image-container img {
  margin-bottom: 15px;
}
.icontable {
  width: 100%;
}
.icontable td {
  vertical-align: top;
}
.icontable td.first-col {
  width: 102px;
}
@media (max-width: 767px) {
  .icontable td.first-col {
    /* xs */
    width: 50px;
  }
  .icontable td.first-col img {
    width: 40px;
  }
}
.icontable td.second-col {
  padding-top: 28px;
}
@media (max-width: 767px) {
  .icontable td.second-col {
    /* xs */
    padding-top: 10px;
  }
}
.download-element {
  display: block;
  background-color: #d1d0cd;
  color: #fff;
  width: 100%;
  padding: 12px 5px 13px 18px;
  margin: 20px 0;
}
.download-element a,
.download-element a:hover {
  color: #fd9128;
  padding-left: 10px;
}
.download-element a:after {
  content: " \00BB";
  color: #fd9128;
  font-weight: bold;
}
.download-element img {
  margin-right: 14px;
}
.download-small-container {
  display: inline-block;
  width: 100%;
  height: auto;
}
.download-element-small {
  display: block;
  background-color: #d1d0cd;
  color: #fff;
  width: 48%;
  min-height: 90px;
  padding: 12px 5px 13px 18px;
  margin: 10px 0;
}
@media (max-width: 767px) {
  .download-element-small {
    /* xs */
    width: 100%;
  }
}
.download-element-small.left {
  float: left;
}
.download-element-small.right {
  float: right;
}
.download-element-small a,
.download-element-small a:hover {
  color: #fd9128;
}
.download-element-small a:after {
  content: " \00BB";
  color: #fd9128;
  font-weight: bold;
}
.download-element-small img,
.download-element-small .icon {
  margin-right: 14px;
  margin-bottom: 50px;
  margin-top: 5px;
  width: 16px;
  float: left;
}
.download-element-small .icon {
  width: 48px;
  opacity: 0.3;
}
/* * * * * * * * * * * * * * * */
/*	 registrierung
/* * * * * * * * * * * * * * * */
.well {
  background-color: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 20px;
  min-height: 20px;
  padding: 10px 0 0 0;
}
.item-page,
.item-pagehome {
  background-color: #ececeb;
  margin-bottom: 30px;
  min-height: 300px;
  padding: 17px 30px;
}
.form-horizontal .control-label {
  margin-bottom: 0;
  padding-top: 7px;
  text-align: left;
}
fieldset {
  margin-bottom: 20px;
}
legend {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: none;
  border-image: none;
  border-style: none none solid;
  border-width: 0 0 0;
  display: block;
  line-height: inherit;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
  font-size: 28px;
  font-weight: 400;
  color: #fd9128;
  line-height: 29px;
}
/* Modul Login */
.login-block {
  min-height: 540px !important;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .login-block {
    /* md */
    min-height: 668px !important;
  }
}
.login-block.login-myasg {
  min-height: 200px !important;
}
.input-small {
  width: 100%;
  margin-bottom: 20px;
}
#form-login-submit {
  margin-top: 20px;
  margin-bottom: 20px;
}
#form-login-remember label {
  font-weight: bold;
}
#login-form ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#login-form a {
  font-weight: bold;
}
#login-form .unstyled li {
  padding: 4px 0;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Module */
.custom {
  padding: 14px 31px;
  margin: 0 0 30px;
  min-height: 255px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .custom {
    /* md */
    min-height: 260px;
  }
}
.custom a:after {
  content: " \00BB";
  color: #fd9128;
  font-weight: bold;
}
.custom a.imagelink:after {
  content: "";
}
.custom a:after {
  color: #fd9128;
}
.custom.grey-medium {
  background-color: #d1d0cd;
}
.custom.grey-medium h2 {
  color: #fff;
}
.custom.grey-light {
  background-color: #ececeb;
}
.custom.grey-light h2 {
  color: #fd9128;
}
.custom.green {
  background-color: #69b6b2;
  color: #fff;
}
.custom.green a,
.custom.green a:hover {
  color: #fff;
}
.custom.green a:after {
  content: " \00BB";
  color: #fff;
  font-weight: bold;
}
.custom.yellow {
  background-color: #f4af0c;
  color: #fff;
}
.custom.yellow a,
.custom.yellow a:hover {
  color: #fff;
}
.custom.yellow a:after {
  content: " \00BB";
  color: #fff;
  font-weight: bold;
}
.custom.orange {
  background-color: #fd9128;
  color: #fff;
}
.custom.orange a,
.custom.orange a:hover {
  color: #fff;
}
.custom.orange a:after {
  content: " \00BB";
  color: #fff;
  font-weight: bold;
}
.custom.big-links {
  padding: 0;
  margin: 0px 0 30px;
  min-height: auto;
  /* font-size: 12px; */
  width: 100%;
}
@media (min-width: 992px) {
  .custom.big-links {
    /* md */
    float: left;
  }
}
.custom.big-links .big-link {
  background-color: #ececeb;
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  width: 100%;
}
@media (min-width: 992px) {
  .custom.big-links .big-link.left {
    float: left;
  }
}
.custom.big-links .big-link.right {
  margin-left: 30px;
}
@media (min-width: 992px) {
  .custom.big-links .big-link.right {
    float: right;
    margin-left: 0px;
  }
}
@media (min-width: 1200px) {
  .custom.big-links .big-link {
    /* width: 170px; */
  }
}
.custom.therapieoptionen {
  background-image: url("../img/htg-teaser-therapieformen.png");
  background-repeat: no-repeat;
  background-position: 208px 75px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .custom.therapieoptionen {
    /* md */
    background-position: 160px 75px;
  }
}
.custom.schmerzformen {
  background-image: url("../img/htg-teaser-schmerzformen.png");
  background-repeat: no-repeat;
  background-position: 182px 30px;
}
.custom.kooperationspraxen {
  position: relative;
  /* für absolut positionierten Link */
  background-image: url("../img/htg-teaser-kooperationspraxen.png");
  background-repeat: no-repeat;
  background-position: 50px 12px;
}
.custom.kooperationspraxen a {
  display: block;
  position: absolute;
  right: 30px;
  bottom: 22px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .custom.kooperationspraxen {
    /* md */
    min-height: 319px;
  }
}
.custom.header {
  padding: 0;
  margin: 0;
}
.custom.header p {
  margin: 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .custom.aerzte-kliniken {
    /* md */
    min-height: 319px;
  }
}
.google-maps {
  position: relative;
  padding-bottom: 255px;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-video {
  position: relative;
  padding-bottom: 56.25%;
  /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0px;
  height: 0;
  overflow: hidden;
  border: 0;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Vorschalt-Seite */
.vorschalt-logo {
  margin-top: 41px;
  margin-left: -5px;
}
@media (max-width: 767px) {
  .vorschalt-logo {
    margin-bottom: 25px;
    margin-top: 20px;
    width: 80%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .vorschalt-blase {
    /* sm */
    margin-top: 30px;
    margin-bottom: 25px;
  }
}
@media (min-width: 1200px) {
  .vorschalt-blase {
    margin-top: -30px;
  }
}
.vorschalt-button {
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .vorschalt-botton-row {
    margin-left: 12%;
  }
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Info Modal */
.modal-backdrop {
  background-color: #fff;
}
.modal-dialog {
  margin: 120px auto;
}
.modal-header .close {
  margin-top: -10px;
  margin-right: -5px;
}
.custom-news {
  min-height: 155px;
  padding: 0 15px;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Schmerzformen */
.sf-container {
  position: relative;
  width: 100%;
  text-align: center;
}
.sf-popup {
  position: absolute;
}
.sf-popup.green h2 {
  color: #69b6b2;
}
.sf-popup.green li:before {
  content: "• ";
  color: #69b6b2;
}
@media (max-width: 767px) {
  .sf-popup {
    background-color: #ececeb;
    padding-bottom: 10px;
  }
}
.sf-popup h2 {
  font-size: 26px;
  font-weight: 600;
  color: #fd9128;
}
@media (min-width: 768px) {
  .sf-popup h2 {
    background: url("../img/sf-header-htg.png") repeat-x;
  }
}
.sf-popup h2.small {
  font-size: 22px;
  line-height: 29px;
}
.sf-popup.left h2 .headline-container {
  background-color: #ececeb;
  padding: 0 7px 0 0;
}
.sf-popup.right h2 .headline-container {
  background-color: #ececeb;
  padding: 0 0 0 7px;
}
/* linke PopUps */
.sf-popup.left {
  text-align: left;
  left: 0px;
  display: none;
}
.sf-container #pos1 {
  /* Kopf */
  top: 8px;
  width: 285px;
}
@media (max-width: 767px) {
  .sf-container #pos1 {
    top: 43px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos1 {
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos1 {
    width: 220px;
  }
}
.sf-container #pos2 {
  /* Nacken */
  top: 88px;
  width: 285px;
}
@media (max-width: 767px) {
  .sf-container #pos2 {
    top: 123px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos2 {
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos2 {
    width: 220px;
  }
}
.sf-container #pos3 {
  /* Rücken */
  top: 279px;
  width: 255px;
}
@media (max-width: 767px) {
  .sf-container #pos3 {
    top: 314px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos3 {
    width: 235px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos3 {
    width: 190px;
  }
}
.sf-container #pos4 {
  /* Visceralschmerz */
  top: 339px;
  width: 325px;
}
@media (max-width: 767px) {
  .sf-container #pos4 {
    top: 374px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos4 {
    width: 305px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos4 {
    width: 260px;
  }
}
.sf-container #pos5 {
  /* Gürtelrose */
  top: 201px;
  width: 325px;
}
@media (max-width: 767px) {
  .sf-container #pos5 {
    top: 236px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos5 {
    width: 305px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos5 {
    width: 260px;
  }
}
.sf-container #pos14 {
  /* Gelenke */
  top: 609px;
  width: 280px;
}
@media (max-width: 767px) {
  .sf-container #pos14 {
    top: 644px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos14 {
    width: 265px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos14 {
    width: 215px;
  }
}
/* rechte PopUps */
.sf-container .sf-popup.right {
  text-align: right;
  right: 0px;
  display: none;
}
.sf-container #pos6 {
  /* Quadrantenschmerz */
  top: 170px;
  width: 290px;
}
@media (max-width: 767px) {
  .sf-container #pos6 {
    top: 205px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos6 {
    width: 270px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos6 {
    width: 225px;
  }
}
.sf-container #pos7 {
  /* Tumorschmerz */
  top: 250px;
  width: 315px;
}
@media (max-width: 767px) {
  .sf-container #pos7 {
    top: 285px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos7 {
    width: 295px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos7 {
    width: 250px;
  }
}
.sf-container #pos8 {
  /* Ganzkörperschmerz */
  top: 291px;
  width: 315px;
}
@media (max-width: 767px) {
  .sf-container #pos8 {
    top: 326px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos8 {
    width: 295px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos8 {
    width: 250px;
  }
}
.sf-container #pos9 {
  /* Komplexregionales Schmerzsyndrom */
  top: 379px;
  width: 220px;
}
@media (max-width: 767px) {
  .sf-container #pos9 {
    top: 414px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos9 {
    width: 200px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos9 {
    width: 155px;
  }
}
.sf-container #pos10 {
  /* Rheumatischer Schmerz */
  top: 413px;
  width: 222px;
}
@media (max-width: 767px) {
  .sf-container #pos10 {
    top: 448px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos10 {
    width: 202px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos10 {
    width: 157px;
  }
}
.sf-container #pos11 {
  /* Vaskuläre Schmerzen */
  top: 497px;
  width: 287px;
}
@media (max-width: 767px) {
  .sf-container #pos11 {
    top: 532px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos11 {
    width: 267px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos11 {
    width: 222px;
  }
}
.sf-container #pos12 {
  /* Polyneuropathie */
  top: 654px;
  width: 287px;
}
@media (max-width: 767px) {
  .sf-container #pos12 {
    top: 689px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos12 {
    width: 267px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos12 {
    width: 222px;
  }
}
.sf-container #pos13 {
  /* Phantomschmerz */
  top: 772px;
  width: 295px;
}
@media (max-width: 767px) {
  .sf-container #pos13 {
    top: 807px;
  }
}
@media (max-width: 991px) {
  .sf-container #pos13 {
    width: 275px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sf-container #pos13 {
    width: 230px;
  }
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Formulare */
#newsForm {
  margin-bottom: 150px;
}
#newsForm .rsform-block-text {
  padding-left: 12px;
  padding-bottom: 30px;
}
#adminForm .btn {
  padding: 4px 12px;
  margin-bottom: 3px;
}
.sectiontablefooter .pagination ul {
  list-style-type: none;
}
.sectiontablefooter .pagination ul li {
  float: left;
  margin: 0 5px;
}
.sectiontablefooter .pagination ul li.pagination-prev {
  margin-right: 15px;
}
.sectiontablefooter .pagination ul li.pagination-next {
  margin-left: 15px;
}
#bestellForm .col-sm-3 {
  width: 49%;
}
#bestellForm .col-sm-6 {
  width: 25%;
}
#bestellForm .rsform-block-zwischentext,
#bestellForm .rsform-block-einfuehrungstext {
  padding-left: 14px;
  padding-top: 20px;
}
#bestellForm .rsform-submit-button {
  margin-top: 20px;
}
#bestellForm .form-control {
  width: auto;
}
#bestellForm .rsform-block-ansprechpartner .control-label,
#bestellForm .rsform-block-praxisname .control-label {
  display: none;
}
#bestellForm .rsform-block-ansprechpartner .col-sm-6,
#bestellForm .rsform-block-praxisname .col-sm-6 {
  width: 50%;
}
#bestellForm .rsform-block-ansprechpartner .form-control,
#bestellForm .rsform-block-praxisname .form-control {
  width: 100%;
}
.formRed {
  color: #cf4d4d;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Dienstplan Formular */
#dienstplanForm .control-label {
  display: none;
}
#dienstplanForm .rsform-block-import {
  padding-left: 14px;
  padding-top: 20px;
}
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Dienstplan */
.dienst-table {
  border: none;
}
.dienst-table td,
.dienst-table th {
  padding: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 9px;
}
.dienst-table th {
  background-color: #eee;
}
.dienst-table th.last-field {
  border: none;
  background-color: transparent;
}
.dienst-table th.border-right,
.dienst-table td.border-right {
  border-right: 3px solid #d6d6d6;
}
.dienst-table td.name-cell {
  font-weight: bold;
  background-color: #eee;
}
.dienst-table td.klvr-cell {
  background-color: #eee;
}
.dienst-table td.highLight,
.dienst-table th.highLight {
  background-color: #ddd !important;
}
.dienst-table td.blue {
  background-color: #dff5ff !important;
}
.dienst-table td.yellow {
  background-color: #fce524 !important;
}
.dienst-table td.green {
  background-color: #f4fff0 !important;
}
.dienst-table .planung1 {
  background-color: #ACE6FD !important;
}
.dienst-table .planung2 {
  background-color: #fdb557 !important;
}
.dienst-table .green-text {
  color: #368e24 !important;
}
td.planungMP {
  background-color: #fffeec !important;
}
td.planungAmbAn {
  background-color: #fdeee2 !important;
}
.dienstplan-section {
  padding: 10px 5px;
}
.blue-text {
  /* Blau im Header */
  background-color: #dff5ff !important;
  padding: 0 5px;
}
@page {
  size: 297mm 210mm;
  margin: 0;
}
/* Cookielayer */
#cookieLayer a {
  color: #fff;
  text-decoration: underline;
}
#cookieLayer a:hover {
  color: #fff;
  text-decoration: underline;
}
#cookieLayer {
  color: #fff;
  text-align: left;
  background: #202528;
  position: fixed;
  bottom: 0px;
  z-index: 10000;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  padding-top: 10px;
  padding-bottom: 14px;
}
#cookieLayer .container {
  position: relative;
  display: block;
  text-align: center;
  width: 960px;
  margin: 0 auto;
}
#cookieLayer .text-block {
  max-width: 1000px;
  padding-right: 20px;
}
#cookieLayerCloser {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 17px;
  height: 17px;
  background: url('../img/close-cross.png');
  background-size: 17px 17px;
  cursor: pointer;
}
